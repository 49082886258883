import React from "react";
import { graphql } from "gatsby";
import ContentMDX from "../components/ContentMDX";
import Layout from "../components/Layout";
import DateFormatter, { DateContainer} from "../components/DateContainer";



const ConferenceMDX = ({ data: { page } }) => {
  const {startDate='', attendanceType='Remote'} = page.frontmatter
  console.log(page.body)
  return <Layout {...page.frontmatter} subtitle={attendanceType}>
    <DateContainer>
        <DateFormatter date={startDate}/>
      </DateContainer>
      {/* <ContentMDX source={page.body} /> */}
    </Layout>
  // return (
  //   <Layout {...page.frontmatter} subtitle={attendanceType}>
  //     <DateContainer>
  //       <DateFormatter date={startDate}/>
  //     </DateContainer>
  //     <ContentMDX source={page.body} />
  //   </Layout>
  // );
};

export default ConferenceMDX;

export const pageQuery = graphql`
  query ConferenceMDX($id: String!) {
    page: mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
        subtitle
        profile
        attendanceType
        startDate
        shortUrl
        featuredImage
      }
    }
  }
`;
